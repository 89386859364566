.card {
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #eceff0;
  border: 1px solid #dfdfdf;
  padding: 3px;

  .icon {
    cursor: pointer;
  }

  h5 {
    font-weight: 500;
    line-height: 1.1;
    font-size: 1rem;
    margin: 0;
  }

  .card-title {
    border-radius: 4px 4px 0 0;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e4e5e9;
  }

  .card-body {
    padding: 10px;
    border: 1px solid #e4e5e9;
    background-color: #eceff0;
  }
}

.info-table {
  width: 100%;

  td:first-child {
    background-color: #364561;
    color: #fff;
    padding: 8px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    white-space: nowrap;
    width: 40%;
  }

  td:last-child {
    padding: 8px;
    width: 100%;
    background-color: #e3e6e9;
  }
}

.stretch {
  &>div {
    display: flex;
  }

  .card {
    width: 100%;
    display: flex;
    flex-flow: column;
  }

  .card-body {
    flex: 1;

    &.right {
      display: flex;
      flex-flow: column;
      align-items: flex-end;

    }
  }
}

